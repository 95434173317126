<div class="successful">
    <div class="container">
        <div class="d-flex justify-content-center" *ngIf="loading">
            <app-loading></app-loading>
        </div>
        <div class="col-md-4" *ngIf="!loading && !error">
            <img src="/assets/images/confetti-2.jpeg" class="mb-2">
            <h1 class="mb-3 text-center">Payment Successful.</h1>
            <button type="button" class="btn btn-info mb-3" (click)="redirectToPaymentsPage()">VIEW PAYMENTS</button>
        </div>
        <div class="col-md-4" *ngIf="!loading && error">
            <h2>UH OH! An Error Occured:</h2>
            <p class="error">{{errMsg}}</p>
        </div>
    </div>
</div> 