import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-successful-payment',
  templateUrl: './successful-payment.component.html',
  styleUrl: './successful-payment.component.scss'
})
export class SuccessfulPaymentComponent implements OnInit{
  /**
   * Success icon
   */
  successIcon = faCircleCheck;
  transactionID: string;
  /**
   * Displays spinner when waiting for transaction to be fetched from backend
   */
  loading: boolean;
  /**
   * Used to check if there is an error
   */
  error: boolean;
  errMsg: string = '';

  /**
   * Injects dependencies into the component
   */
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private router: Router,
    private auth: AuthenticationService,
  ) {}

  /**
   * Retrieves the transactionID from the URL
   */
  ngOnInit(): void {
    this.loading = true;
    this.transactionID = this.route.snapshot.url[1].path;
    if (this.transactionID) {
      this.loading = true;
      this.confirmTransaction();
    } else {
      this.loading = false;
      this.notify.showError('Transaction ID not provided');
      this.error = true;
    }
  }


  confirmTransaction(): void {
    const transaction = {
      id: this.transactionID
    }
    this.api.put('/transactions/confirmTransaction', JSON.parse(JSON.stringify(transaction))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          localStorage.setItem('token', this.auth.encrypt(res.detail.token));
          localStorage.setItem('role', "client");
          localStorage.setItem('userID', this.auth.encrypt(res.detail.client_id));
          this.auth.authenticate(res.detail.client_id);
          this.loading = false;
          this.error = false;
        } else {
          this.error = true;
          this.errMsg = res.detail;
          this.loading = false;
        }
        this.loading = false;
      },
      error: (e) => {
        this.notify.showError(e);
        this.loading = false;
      }
    });
  }

  redirectToPaymentsPage() {
    this.router.navigate(['/payments']);
  }
}
