import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { Job } from '../../../shared/Interfaces/Job';
import moment from 'moment-timezone';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { PagingConfig } from '../../../shared/Interfaces/PagingConfig';

@Component({
  selector: 'app-post-job',
  templateUrl: './post-job.component.html',
  styleUrl: './post-job.component.scss'
})
export class PostJobComponent implements OnInit{
  /**
   * Edit icon
   */
  editIcon = faPenToSquare;
  /**
   * Delete icon
   */
  deleteIcon = faTrash;
  /**
   * Paid icon
   */
  paidIcon = faThumbsUp;
  /**
   * Minimum date of 'Deadline'
   * Initialized to the current date
   */
  today: Date = new Date();
  /**
   * Stores the state of the form for form validation and displaying validation errors
   */
  submitted = false;
  /**
   * Indicates whether a category has been selected.
   * 
   * When `noCategorySelected` is true, it signifies that no category has been chosen.
   * This variable is typically used to control conditional logic based on whether a category has been selected or not.
   * 
   */
  noCategorySelected: boolean;
  /**
   * Displays spinner when waiting for jobs to be fetched from backend
   */
  updateJob = false;
  updateJobID: string = '';
  /**
   * Displays spinner when waiting for jobs to be fetched from backend
   */
  isLoading: boolean;
  /**
   * Used to disable button and display spinner when create job button is clicked
   */
  isLoading2 = false;
  /**
   * Used to disable button and display spinner when canceling a job
   */
  cancelJobLoading = false;
  /**
   * Form with input fields for creating a new job
   */
  postJobForm: FormGroup;
  /**
   * Form with input fields for creating a new job
   */
  milestoneForm: FormGroup;
  /**
   * Variable to store the client's jobs
   */
  jobs: Job[] = [];
  /**
   * Used to check if there are any jobs
   */
  jobsAvailable: boolean;
  /**
   * Variable to store selected days
   */
  selectedCategories: string[] = [];
  /**
   * Variable to store selected days
   */
  previousCategories: string[] = [];
  /**
   * The current budget of the job being updated
   */
  currentJobBudget: number = 0;
  /**
   * The services being offered
   */
  services = [
    {
      title: 'Digital Marketing',
      selected: false
    },
    {
      title: 'Data Science',
      selected: false
    },
    {
      title: 'Software Development',
      selected: false
    },
    {
      title: 'Graphic Design',
      selected: false
    },
    {
      title: 'UI UX',
      selected: false
    },
    {
      title: 'Video Editing',
      selected: false
    },
    {
      title: 'Transcription',
      selected: false
    },
    {
      title: 'Copywriting',
      selected: false
    },
    {
      title: 'Accounting',
      selected: false
    },
    {
      title: 'AI Services',
      selected: false
    },
  ]

  @ViewChild('postJobModal', { static: true }) postJobModal: TemplateRef<any>;
  @ViewChild('cancelJobModal', { static: true }) cancelJobModal: TemplateRef<any>;

  jobsPagingConfig: PagingConfig = {} as PagingConfig;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private notify: NotificationService,
    private modal: NgbModal
  ) {}

  /**
   * Fetches jobs when the component is initialized
   * Sets the default values of the forms
   */
  ngOnInit(): void {
    this.jobsAvailable = false;
    this.fetchJobs();
    this.postJobForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      budget: ['', Validators.required],
      skills: ['', Validators.required],
      duration: ['', Validators.required],
      period: ['', Validators.required],
      deadline: ['', Validators.required],
      milestones: this.formBuilder.array([])
    });
    this.jobsPagingConfig = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: 0
    }
  }

  get milestones(): FormArray {
    return this.postJobForm.get('milestones') as FormArray;
  }

  deleteMilestone(index: number) {
    this.milestones.removeAt(index);
  }

  addMilestone() {
    const milestoneId = this.generateMilestoneId(); // Generate a unique ID for the milestone
    this.milestones.push(
      this.milestoneForm = this.formBuilder.group({
        milestoneId: [milestoneId], // Add the milestone ID to the form group
        milestoneDuration: ['', Validators.required],
        milestonePeriod: ['', Validators.required],
        milestoneDeadline: ['', Validators.required],
        milestoneDescription: ['', Validators.required],
      })
    );
  }

  generateMilestoneId(): string {
    // Generate a unique ID using a timestamp or any other method
    return 'milestone_' + new Date().getTime(); // Example: milestone_1621537152682
  }

  /**
   * Getter for all the Job form controls
   */
  get f(): FormGroup['controls'] {
    return this.postJobForm.controls;
  }

  /**
   * Getter for all the milestone form controls
   */
  get g(): FormGroup['controls'] {
    return this.milestoneForm.controls;
  }

  /**
   * Displays the modal to cancel a job
   */
  openCancelJobModal(job: Job): void {
    this.updateJobID = job._id;
    this.modal.open(this.cancelJobModal, { size: 'md', centered: true });
  }

  /**
   * Displays the modal to post or update a job
   */
  openPostJobModal(updateJob: boolean = false, job?: Job): void {
    this.previousCategories = [];
    if (!updateJob) {
      this.updateJob = false;
      this.postJobForm.reset();
      this.resetCategorysSelection();
      this.modal.open(this.postJobModal, { size: 'lg', centered: true });
    } else {
      if (job) {
        this.currentJobBudget = 0;
        this.updateJobID = job._id;
        this.updateJob = true;
        this.postJobForm.get('title')?.setValue(job.title);
        this.postJobForm.get('description')?.setValue(job.description);
        this.postJobForm.get('budget')?.setValue(job.budget);
        this.currentJobBudget = job.budget;
        this.postJobForm.get('skills')?.setValue(job.skills);

        if (this.milestones.value.length < job.milestones.length) {
          job.milestones.forEach( () => {
            this.addMilestone();
          });
        }

        this.postJobForm.patchValue({
          milestones: job.milestones
        });
        const parts = job.duration.split(' ');
        this.postJobForm.get('duration')?.setValue(parts[0]);
        if (parts[1].endsWith('s')) {
          // Remove the last character from the string
          this.postJobForm.get('period')?.setValue(parts[1].slice(0, -1));
        } else {
          this.postJobForm.get('period')?.setValue(parts[1]);
        }
        this.postJobForm.get('deadline')?.setValue(job.deadline);
        this.previousCategories = job.categories;
        this.modal.open(this.postJobModal, { size: 'lg', centered: true });
      }
    }
  }

  /**
   * Fetches jobs from the server.
   * 
   * This method sends a request to the server to retrieve job data and updates the component's state accordingly.
   * - If the request is successful (status code 200) and there are jobs available, it sets `jobsAvailable` to true
   *   and assigns the received job details to the `jobs` array.
   * - If the request is successful but there are no jobs available, `jobsAvailable` remains false.
   * - If the request fails (status code 1001), it displays an error notification using the `notify.showError()` method.
   * - Finally, it sets `isLoading` to false to indicate that the data loading process has finished.
   * 
   * Note: This method assumes the existence of an `api` service with a `get` method for making HTTP GET requests
   * and a `notify` service with a `showError` method for displaying error notifications.
   */
  fetchJobs() {
    this.isLoading = true;
    this.jobs = [];
    this.api.get('/jobs/get-client-jobs').subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.jobsPagingConfig.totalItems = res.detail.length;
          if (res.detail.length > 0) {
            this.jobsAvailable = true;
            this.jobs = res.detail;
          } 
        } else {
          this.notify.showError(res.detail);
        }
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        console.error(e);
      }
    }); 
  }

  /**
   * Posts a job to the server.
   * 
   * This method is responsible for submitting a job creation request to the server, 
   * processing the server's response, and updating the component's state accordingly.
   * - It sets `submitted` to true to indicate that a job submission process has begun.
   * - It validates the job form and ensures that at least one category is selected before proceeding.
   * - If the form is invalid or no category is selected, it sets `noCategorySelected` to true and returns.
   * - It sets `isLoading2` to true to indicate that the job creation process is in progress.
   * - It constructs the job object using the form values and selected categories, including formatting duration and deadline.
   * - It sends a POST request to the server with the job data and subscribes to the response.
   * - If the request is successful (status code 200), it fetches updated job data from the server,
   *   resets the job form, dismisses any open modals, and displays a success notification.
   * - If the request fails with specific error status codes (1000, 1001, 1002), it displays an error notification.
   * - If the request encounters an error, it displays an error notification.
   * - Finally, it dismisses the post job modal.
   * 
   * Note: This method assumes the existence of an `api` service with `post` method for making HTTP POST requests,
   * a `notify` service with `showSuccess` and `showError` methods for displaying success and error notifications respectively,
   * and a `modal` service with `dismissAll` method for dismissing modals.
   */
  postJob(): void {
    this.submitted = true;

    if (this.postJobForm.invalid) {
      return;
    }

    if (this.selectedCategories.length === 0) {
      this.noCategorySelected = true;
      return;
    }

    const budget = this.postJobForm.get('budget')?.value;

    if (budget < 10) {
      this.notify.showError(`The minimum budget is 10 USD.`);
      return;
    }

    this.isLoading2 = true;

    const deadline = (this.postJobForm.get('deadline')?.value);

    const duration = this.postJobForm.get('duration')?.value;
    const period = this.postJobForm.get('period')?.value;
    const durationText = `${duration} ${period}${duration === 1 ? '' : 's'}`;

    const job = {
      title: this.postJobForm.get('title')?.value,
      description: this.postJobForm.get('description')?.value,
      budget: this.postJobForm.get('budget')?.value,
      duration: durationText,
      deadline: moment(deadline).format('YYYY-MM-DD'),
      skills: this.postJobForm.get('skills')?.value,
      categories: this.selectedCategories,
      milestones: this.milestones.value
    }

    this.api.post('/jobs/create-job', JSON.parse(JSON.stringify(job))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.postJobForm.reset();
          this.isLoading2 = false;
          this.submitted = false;
          this.notify.showSuccess(res.detail);
          this.modal.dismissAll(this.postJobModal);
          this.fetchJobs();
        } else {
          this.notify.showError(res.detail);
          this.isLoading2 = false;
          this.modal.dismissAll(this.postJobModal);
        }
      },
      error: (e) => {
        this.notify.showError(e);
        this.isLoading2 = false;
      }
    });
    
  }

  editJob(): void {
    this.submitted = true;

    if (this.postJobForm.invalid) {
      return;
    }

    if (this.previousCategories.length === 0 && this.selectedCategories.length === 0) {
      this.noCategorySelected = true;
      return;
    }

    const budget = this.postJobForm.get('budget')?.value;

    if (budget < this.currentJobBudget) {
      this.notify.showError(`The budget can not be reduced. Input an amount equal to or greater than ${this.currentJobBudget}.`);
      return;
    }

    if (budget < 10) {
      this.notify.showError(`The minimum budget is 10 USD.`);
      return;
    }

    this.isLoading2 = true;

    const deadline = (this.postJobForm.get('deadline')?.value);
    const duration = this.postJobForm.get('duration')?.value;
    const period = this.postJobForm.get('period')?.value;
    const durationText = `${duration} ${period}${duration === 1 ? '' : 's'}`;

    const job = {
      job_id: this.updateJobID,
      title: this.postJobForm.get('title')?.value,
      description: this.postJobForm.get('description')?.value,
      budget: this.postJobForm.get('budget')?.value,
      duration: durationText,
      deadline: moment(deadline).format('YYYY-MM-DD'),
      skills: this.postJobForm.get('skills')?.value,
      categories: this.selectedCategories.concat(this.previousCategories),
      milestones: this.milestones.value
    }
  
    this.api.put('/jobs/', JSON.parse(JSON.stringify(job))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.fetchJobs();
          this.isLoading2 = false;
          this.postJobForm.reset();
          this.submitted = false;
          this.notify.showSuccess(res.detail);
        } else {
          this.notify.showError(res.detail);
          this.isLoading2 = false;
        }
        this.updateJob = false;
      },
      error: (e) => {
        this.notify.showError(e);
        this.isLoading2 = false;
        this.updateJob = false;
      }
    });
    this.modal.dismissAll(this.postJobModal);
  }

  /**
   * 
   */
  cancelJob(): void {
    this.cancelJobLoading = true;

    const job = {
      job_id: this.updateJobID
    };

    this.api.put('/jobs/cancel-job', JSON.parse(JSON.stringify(job))).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.fetchJobs();
          this.notify.showSuccess(res.detail);
        } else {
          this.notify.showError(res.detail);
        }
        this.modal.dismissAll(this.cancelJobModal);
        this.cancelJobLoading = false;
      },
      error: (e) => {
        this.notify.showError(e);
        this.cancelJobLoading = false;
      }
    });
  }

  /**
   * Function to handle category selection.
   * 
   * @param category - The category object representing the category to toggle selection for.
   */
  toggleCategorySelection(category: any): void {
    category.selected = !category.selected;
    this.updateSelectedCategories();
  }

  /**
   * Detects if the data in the submissions has changed
   * @param page - The page that has been selected
   */
  onjobsDataChange(event: any): void{
    this.jobsPagingConfig.currentPage = event;
    this.fetchJobs();
  }

  /**
   * Reset the categorys selection to false
   */
  resetCategorysSelection() {
    this.services = [
      {
        title: 'Digital Marketing',
        selected: false
      },
      {
        title: 'Data Science',
        selected: false
      },
      {
        title: 'Software Development',
        selected: false
      },
      {
        title: 'Graphic Design',
        selected: false
      },
      {
        title: 'UI UX',
        selected: false
      },
      {
        title: 'Video Editing',
        selected: false
      },
      {
        title: 'Transcription',
        selected: false
      },
      {
        title: 'Copywriting',
        selected: false
      },
      {
        title: 'Accounting',
        selected: false
      },
      {
        title: 'AI Services',
        selected: false
      },
    ];
  }
  
  /** 
   * Function to update selected days variable
   */
  private updateSelectedCategories(): void {
    this.selectedCategories = this.services.filter(service => service.selected).map(service => service.title);
  }

  cancelModal(): void {
    this.submitted = false;
    this.postJobForm.reset();
    this.modal.dismissAll(this.postJobModal);
  }
}
