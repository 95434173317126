import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostJobComponent } from './components/post-job/post-job.component';
import { AuthGuard } from '../auth/auth-guard/auth-guard.guard';
import { Role } from '../shared/models/role.model';
import { FreelancersComponent } from './components/freelancers/freelancers.component';
import { JobDetailsComponent } from './components/job-details/job-details.component';
import { ApplicantProfileComponent } from './components/applicant-profile/applicant-profile.component';
import { SuccessfulPaymentComponent } from './components/successful-payment/successful-payment.component';
import { CancelledPaymentComponent } from './components/cancelled-payment/cancelled-payment.component';
import { FreelancerProfileComponent } from '../pages/components/freelancer-profile/freelancer-profile.component';
import { PaymentsComponent } from './components/payments/payments.component';

const routes: Routes = [
  { path: 'my-jobs', component : PostJobComponent, canActivate: [AuthGuard], data: {roles: [Role.Client]} },
  { path: 'freelancers', component : FreelancersComponent, canActivate: [AuthGuard], data: {roles: [Role.Client]} },
  { path: 'payments', component: PaymentsComponent, canActivate: [AuthGuard], data: {roles: [Role.Client]} },
  { path: 'posted-jobs/:ID', component: JobDetailsComponent, canActivate: [AuthGuard], data: {roles: [Role.Client]} },
  { path: 'posted-jobs/:job_ID/applicants/:applicant_ID', component: ApplicantProfileComponent, canActivate: [AuthGuard], data: {roles: [Role.Client]} },
  { path: 'profile/:applicant_ID', component: FreelancerProfileComponent, canActivate: [AuthGuard], data: {roles: [Role.Client]} },
  { path: 'successful-payment/:transactionID', component : SuccessfulPaymentComponent },
  { path: 'canceled-payment', component : CancelledPaymentComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
