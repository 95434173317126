<div class="container">
    <div class="action" *ngIf="!jobLoading && this.jobID">
        <button class="btn btn-info" (click)="displayReviewFreelancerModal()" *ngIf="job.status == 'Completed' && !job.freelancer.reviewed">REVIEW FREELANCER</button>
        <a class="btn btn-info mb-3" [href]="'/posted-jobs/' + jobID" *ngIf="!userLoading && jobID">GO BACK TO APPLICANTS</a>
    </div>

    <div class="d-flex justify-content-center" *ngIf="userLoading">
        <app-loading></app-loading>
    </div>
    <div class="profile" *ngIf="!userLoading && this.ID">
        <div class="row">
            <div class="col-lg-2">
                <div class="avatar">
                    <div class="avatar-preview">
                      <div class="image-preview" [style.backgroundImage]="'url('+ user.profile_image +')'"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-10">
                <div class="name">
                    <h1>{{user.firstname}} {{user.lastname}}</h1>
                    <h3>{{user.title}}</h3>
                    <ngb-rating [max]="5" [(rate)]="user.average_rating" [readonly]="true"></ngb-rating>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <h3>Bio</h3>
            <p>{{user.bio}}</p>
        </div>
        <div class="mb-2">
            <h3>Skills</h3>
            <span class="badge rounded-pill text-bg-light" *ngFor="let skill of user.skills ">{{skill}}</span>
        </div>
        <div class="resume mb-3" *ngIf="user.resume">
            <h3>Resume</h3>
            <button class="btn btn-info" (click)="downloadResume(user.resume)" [disabled]="downloadingResume" *ngIf="user.resume">
                DOWNLOAD RESUME
                <span *ngIf="downloadingResume" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true">
                </span>
            </button>
        </div>
        <div class="row reviews" *ngIf="user.reviews">
            <h3>Reviews</h3>
            <div class="col-md-3" *ngFor="let review of user.reviews">
                <div class="card mb-2">
                    <div class="card-body">
                        <ngb-rating [max]="5" [(rate)]="review.rating" [readonly]="true"></ngb-rating>
                        <p class="card-text mb-3">{{review.comment}}</p>
                        <h6 class="card-subtitle mb-2 text-muted">{{review.firstname}} {{review.lastname}}</h6>
                        <h6 class="card-subtitle text-muted date">{{review.reviewed_on | date: 'mediumDate'}}</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="user.reviews.length === 0">
                <p>There are no reviews yet.</p>
            </div>
        </div>
    </div>
</div>

<!-- REVIEW FREELANCER MODAL -->
<ng-template #reviewFreelancerModal let-close="close">
    <div class="modal-header">
        <h5 class="modal-title">Review Freelancer</h5>
    </div>
    <div class="modal-body">
        <div class="rating mb-3">
            <h5>Rate Freelancer</h5>
            <ngb-rating [max]="5" [(rate)]="starRating" [readonly]="false"></ngb-rating>
        </div>
        <form [formGroup]="reviewForm">
            <div class="form-group">
                <label for="comment">Comment</label>
                <textarea class="form-control" formControlName="comment" rows="4"
                    placeholder="Add a comment" autofocus
                    [ngClass]="{ 'is-invalid': submitted && f['comment'].errors }">
                </textarea>
                <div *ngIf="submitted && f['comment'].errors" class="invalid-feedback">
                    <div *ngIf="f['comment']['errors']required">Comment is required</div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-dark" (click)="close()">CLOSE</button>
        <button class="btn btn-dark" (click)="reviewFreelancer()" [disabled]="reviewLoading">SUBMIT REVIEW
            <span *ngIf="reviewLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button>
    </div>
</ng-template>
