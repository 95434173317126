import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { User } from '../../../shared/Interfaces/User';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Job } from '../../../shared/Interfaces/Job';

@Component({
  selector: 'app-applicant-profile',
  templateUrl: './applicant-profile.component.html',
  styleUrl: './applicant-profile.component.scss'
})
export class ApplicantProfileComponent implements OnInit {
  /**
   * Stores the applicant's details
   */
  user: User;
  /**
   * Used to display spinner when waiting for the user to be fetched from the backend
   */
  userLoading: boolean;
  /**
   * Used to display spinner when waiting for the job to be fetched from the backend
   */
  jobLoading: boolean;
  /**
   * Stores the applicant ID received from the history state
   */
  ID: string;
  /**
   * Stores the job ID received from the history state
   */
  jobID: string;
  /**
   * Used to display spinner when uploading resume
   */
  downloadingResume: boolean;
  /**
   * Form with input fields for reviewing a freelancer
   */
  reviewForm: FormGroup;
  /**
   * Stores the state of the form for form validation and displaying validation errors
   */
  submitted = false;
  starRating = 0;
  reviewLoading: boolean;
  /**
   * Displays spinner when waiting for the selected freelancer to be approved
   */
  approveLoading: boolean;
  /**
   * Displays spinner when waiting for the payment to be made
   */
  paymentLoading: boolean;
  /**
   * The job whose details are being displayed
   */
  job: Job;
  /**
   * Modal for reviewing a freelancer
   */
  @ViewChild('reviewFreelancerModal', { static: true }) reviewFreelancerModal: TemplateRef<any>;


  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param authService - Authentication service
   */
  constructor(
    private api: ApiService,
    private notify: NotificationService,
    private router: Router,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private modal: NgbModal,
    private route: ActivatedRoute
  ) { }

  /**
   * Fetches the applicant's details
   */
  ngOnInit(): void {
    this.ID = this.route.snapshot.url[3].path;
    this.jobID = this.route.snapshot.url[1].path;
    this.jobLoading = true;
    if (this.ID) {
      this.fetchUser();
    }
    
    if (this.jobID) {
      this.fetchJob();
    }
    this.reviewForm = this.formBuilder.group({
      comment: ['', Validators.required],
    });
  }

  /**
   * Getter for all the review form controls
   */
  get f(): FormGroup['controls'] {
    return this.reviewForm.controls;
  }

  /**
   * Fetches the applicant's details and their profile
   */
  fetchUser(): void {
    this.userLoading = true;
    this.api.get('/users/' + this.ID).subscribe(
      res => {
        if (res.status_code === 200) {
          this.user = res.detail;
        } else {
          this.notify.showError(res.detail);
          this.ID = '';
        }
        this.userLoading = false;
      }
    );
  }

  /**
   * Fetches the job details from an API endpoint
   */
  fetchJob(): void {
    if (this.jobID) {
      this.api.get('/jobs/' + this.jobID).subscribe(
        res => {
          if (res.status_code === 200) {
            this.job = res.detail;
          } else {
            this.notify.showError(res.detail);
            this.jobID = '';
          }
          this.jobLoading = false;
        }
      );
    } else {
      this.jobLoading = false;
      this.router.navigate(['home']);
    }
  }

  downloadResume(resume: string): void {
    this.downloadingResume = true;
    this.api.getFile('/users/download-resume/' + this.user._id).subscribe(
      blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = resume;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.downloadingResume = false;
      }
    )
  }

  displayReviewFreelancerModal(): void {
    this.modal.open(this.reviewFreelancerModal, { size: 'md', centered: true });
  }

  reviewFreelancer(): void {
    this.submitted = true;

    if (this.reviewForm.invalid) {
      return;
    }

    this.reviewLoading = true;

    const data = {
      freelancer_id: this.user._id,
      reviewer_id: this.authService.userID,
      comment: this.reviewForm.get('comment')?.value,
      rating: this.starRating,
      job_id: this.jobID
    }

    this.api.put('/users/review-freelancer', JSON.parse(JSON.stringify(data))).subscribe({
      next: response => {
        if (response.status_code === 200) {
          this.reviewForm.reset();
          this.notify.showSuccess(response.detail);
          this.fetchUser();
          this.fetchJob();
        } else {
          this.notify.showError(response.detail);
        }
        this.reviewLoading = false;
      },
      error: (e) => {
        this.reviewLoading = false;
        this.notify.showError(e);
      }
    });
    this.modal.dismissAll(this.reviewFreelancerModal);
  }
}

