import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgxStripeModule} from "ngx-stripe";

import { ClientRoutingModule } from './client-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PostJobComponent } from './components/post-job/post-job.component';
import { FreelancersComponent } from './components/freelancers/freelancers.component';
import { JobDetailsComponent } from './components/job-details/job-details.component';
import { ApplicantProfileComponent } from './components/applicant-profile/applicant-profile.component';
import { SuccessfulPaymentComponent } from './components/successful-payment/successful-payment.component';
import { CancelledPaymentComponent } from './components/cancelled-payment/cancelled-payment.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PagesModule } from '../pages/pages.module';
import { PaymentsComponent } from './components/payments/payments.component';


@NgModule({
  declarations: [
    PostJobComponent,
    FreelancersComponent,
    JobDetailsComponent,
    ApplicantProfileComponent,
    SuccessfulPaymentComponent,
    CancelledPaymentComponent,
    PaymentsComponent
  ],
  imports: [
    CommonModule,
    ClientRoutingModule,
    SharedModule,
    NgSelectModule,
    PagesModule,
    NgxStripeModule.forRoot()
  ]
})
export class ClientModule { }
