import { Component } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';

@Component({
  selector: 'app-cancelled-payment',
  templateUrl: './cancelled-payment.component.html',
  styleUrl: './cancelled-payment.component.scss'
})
export class CancelledPaymentComponent {
  /**
   * Adds dependencies to the component
   */
  constructor(
    private auth: AuthenticationService,
  ) { 
    // Retrieve authentication status and user information from local storage if available
    const storedRole = localStorage.getItem('role');
    const storedUserID = this.auth.decrypt(localStorage.getItem('userID'));
    if (storedRole && storedUserID) {
      this.auth.role = storedRole;
      this.auth.userID = storedUserID;
      this.auth.authenticated = true;
      this.auth.startHeartbeat();
      this.auth.startUnreadCount();
    }
  }
}
