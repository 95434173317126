<div class="container">
  <div class="mb-3">
    <ng-select (change)="changeFn($event)" [(ngModel)]="selectedCategory" [items]="categories" appendTo="body" placeholder="Select Category"></ng-select>
  </div>
  <div class="d-flex justify-content-center" *ngIf="fetchingFreelancers">
    <app-loading></app-loading>
  </div>
  <div *ngIf="freelancers.length === 0 && !fetchingFreelancers">
    <h2>UH OH!</h2>
    <p>There are no freelancers for this category yet.</p>
  </div>
  <div class="freelancers" *ngIf="!fetchingFreelancers">
    <div *ngIf="freelancers.length > 0 && !selectedCategory">
      <h2>All Freelancers</h2>
    </div>
    <div *ngIf="freelancers.length > 0 && selectedCategory">
      <h2>{{selectedCategory}} Freelancers</h2>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-6 col-lg-3 mb-4" *ngFor="let freelancer of freelancers | paginate : {
          itemsPerPage: items,
          currentPage: page,
          totalItems: count
        };
        let i = index">
        <div class="card" style="width: 100%;">
          <div class="card-body">
            <div class="avatar">
              <div class="avatar-preview">
                <div class="image-preview" [style.backgroundImage]="'url('+ freelancer.profile_image +')'"></div>
              </div>
            </div>
            <h5 class="card-title">{{freelancer.firstname}} {{freelancer.lastname}}</h5>
            <p class="card-text text-center">{{freelancer.title}}</p>
            <p class="card-text text-center">
              <span class="badge rounded-pill text-bg-light" *ngFor="let skill of freelancer.skills ">{{skill}}</span>
            </p>
            <a class="btn btn-info" [href]="'/profile/' + freelancer._id">VIEW PROFILE</a>
            <button class="btn btn-info" (click)="sendMessage(freelancer)">SEND MESSAGE</button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="freelancers.length > 0">
      <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
      </pagination-controls>
    </div>
  </div>
</div>