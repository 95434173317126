import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { User } from '../../../shared/Interfaces/User';

@Component({
  selector: 'app-freelancers',
  templateUrl: './freelancers.component.html',
  styleUrl: './freelancers.component.scss'
})
export class FreelancersComponent {
  /**
   * Stores the current page of the paginator
   */
  page = 1;
  /**
   * Stores the count of the paginator
   */
  count = 0;
  /**
   * Stores the total items displayed per page
   */
  items = 9;
  /**
   * Variable to store the freelancers
   */
  freelancers: User[] = [];
  /**
   * Displays spinner when waiting for data to be fetched from backend
   */
  fetchingFreelancers: boolean;

  categories = [
    "Digital Marketing",
    "Data Science",
    "Software Development",
    "Graphic Design",
    "UI UX",
    "Video Editing",
    "Transcription",
    "Copywriting",
    "Accounting",
    "AI Services"
  ];

  selectedCategory: string;

  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for redirection
   */
  constructor(
    private api: ApiService,
    private router: Router,
    private notify: NotificationService,
  ) { }

  /**
   * Fetches all freelancers when component is initialized
   */
  ngOnInit(): void {
    this.fetchFreelancers();
  }

  fetchFreelancers(): void {
    this.fetchingFreelancers = true;
    this.freelancers = [];
    this.api.get('/users/get-freelancers').subscribe(
      res => {
        if (res.status_code === 200) {
          this.freelancers = res.detail;
        } else {
          this.notify.showError(res.detail);
        }
        this.fetchingFreelancers = false;
      }
    );
  }

  /**
   * Detects if the data in the table has changed
   * @param page - The page that has been selected
   */
  onTableDataChange(page: number): void {
    this.page = page;
    this.fetchFreelancers();
  }

  changeFn(selectedValue: string): void {
    if (selectedValue) {
      this.selectedCategory = selectedValue;
      this.fetchSelectedServiceFreelancers();
    } else {
      this.fetchFreelancers();
    }
  }

  /**
   * Fetches freelancers based on a specified category, removing hyphens if present in the category.
   * @returns {void} - This function does not return a value directly, but updates component state upon completion.
   */
  fetchSelectedServiceFreelancers(): void {
    this.freelancers = [];
    this.fetchingFreelancers = true;
    this.api.get('/users/get-service-freelancers/' + this.selectedCategory).subscribe(
      res => {
        if (res.status_code === 200) {
          this.freelancers = res.detail;
        } else {
          this.notify.showError(res.detail);
        }
        this.fetchingFreelancers = false;
      }
    );
  }

  sendMessage(freelancer: User) {
    this.router.navigate(['messages'], {
      state: { 
        _id: freelancer._id,
        firstname: freelancer.firstname,
        lastname: freelancer.lastname 
      }
    });
  }

}
