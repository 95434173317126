import { Component } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { PagingConfig } from '../../../shared/Interfaces/PagingConfig';
import { Transaction } from '../../../shared/Interfaces/Transaction';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrl: './payments.component.scss'
})
export class PaymentsComponent {
  /**
   * Displays spinner when waiting for payments to be fetched from backend
   */
  isLoading: boolean;
  /**
   * Variable to store the payments made or received by the user
   */
  transactions: Transaction[] = [];
  totalPayments: Number;
  /**
   * Used to check if there are any transactions
   */
  transactionsAvailable: boolean;

  paymentsPagingConfig: PagingConfig = {} as PagingConfig;
  role: string;

  /**
   * Adds dependencies into the component.
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for redirection
   */
  constructor(
    private authService: AuthenticationService,
    private api: ApiService,
    private notify: NotificationService,
  ) {}

  /**
   * Fetches jobs when the component is initialized
   * Sets the default values of the forms
   */
  ngOnInit(): void {
    this.fetchTransactions();
    this.paymentsPagingConfig = {
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: 0
    }
  }

  fetchTransactions() {
    this.isLoading = true;
    this.transactions = [];
    const user_id = this.authService.userID;
    this.role = this.authService.role;
    this.api.get(`/transactions/payments?user_id=${user_id}&user_type=${this.role}`).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.paymentsPagingConfig.totalItems = res.detail.length;
          if (res.detail.length > 0) {
            this.transactionsAvailable = true;
            this.transactions = res.detail;
            this.totalPayments = res.total_amount;
          } 
        } else {
          this.notify.showError(res.detail);
        }
        this.isLoading = false;
      },
      error: (e) => {
        this.isLoading = false;
        console.error(e);
      }
    }); 
  }

  /**
   * Detects if the data in the payments has changed
   * @param page - The page that has been selected
   */
  onPaymentsDataChange(event: any): void{
    this.paymentsPagingConfig.currentPage = event;
    this.fetchTransactions();
  }
}
